<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="listUserGifts"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="elevation-1"
                @page-count="pageCount = $event"
        >
            <template v-slot:item.type="{ item }">
                <div v-if="item.type == 1">
                    Nhận Quà
                </div>
                <div v-if="item.type == 2">
                    Nhận thông báo mới nhất
                </div>
            </template>
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination
                    v-model="page"
                    :length="pageCount"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    import {
        GET_LIST_USER_GIFT,
    } from "@/store/user.module";
    import {mapGetters} from "vuex";
    import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
    export default {
        computed: {
            ...mapGetters({
                listUserGifts: "listUserGifts"
            }),
        },
        mounted() {
            this.getAdmins();
        },
        data: () => ({
            headers: [
                {
                    text: "#id",
                    align: "start",
                    sortable: false,
                    value: "id"
                },
                { text: "Tên", value: "name" },
                { text: "Email", value: "email" },
                { text: "Số điện thoại", value: "phone" },
                { text: "Loại người dùng", value: "type" },
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
        }),
      created() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "User nhận quà", titledad:'Người dùng' }]);
      },
      methods: {
            getAdmins() {
                this.$store.dispatch(GET_LIST_USER_GIFT);
            },

        }
    };
</script>
